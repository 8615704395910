import styled, { css, keyframes } from 'styled-components';
import { minWidth, onlyWidth } from 'helpers/buildMediaQueries';
import {
  Heading,
  fontVariables,
  colorsVariables,
  flexColumn,
  flexItemsCentered,
  gutterVariables,
  zIndicesVariables
} from '@trustedshops/tps-seo-shared-frontend-react-components';

const slideDownAnimationMobile = keyframes`
  0% {
    position: absolute;
    transform: translateY(-400px);
  }
  
  100% {
    transform: translateY(-80px);
    position: fixed;

    ${minWidth(
      'xxl',
      `
      transform: translateY(0);
    `
    )};
  }
`;

const slideDownAnimation = keyframes`
  0% {
    position: absolute;
    transform: translateY(-400px);
  }
  
  100% {
    transform: translateY(-100px);
    position: fixed;
  }
`;

const slideUpAnimationMobile = keyframes`
  0% {
    transform: translateY(-80px);
  }
  
  100% {
    transform: translateY(0);
    position: fixed;
  }
`;

const slideUpAnimation = keyframes`
  0% {
    transform: translateY(-100px);
  }
  
  100% {
    transform: translateY(0);
    position: fixed;
  }
`;

const slideBackUpAnimationMobile = keyframes`
  0% {
    transform: translateY(0);
  }
  
  100% {
    transform: translateY(-80px);
  }
`;

const slideBackUpAnimation = keyframes`
  0% {
    transform: translateY(0);
  }
  
  100% {
    transform: translateY(-100px);
  }
`;
export const CompanyHeader = styled.div<{
  hasNoReviews?: boolean;
  isModalOpen?: boolean;
  hasExecutedInitialAnimation?: boolean;
}>`
  ${flexItemsCentered};
  flex-direction: column;
  grid-area: 1 / 1 / 1 / 5;
  width: 100%;
  position: relative;
  text-align: center;
  justify-content: center;
  padding: 16px 16px 64px 16px;
  gap: 32px;
  margin-bottom: ${gutterVariables.GUTTER_PLUS_HALF};
  background-color: ${colorsVariables.WHITE};
  box-shadow:
    0 3px 13px -1px rgba(5, 55, 95, 0.12),
    0 1.5px 4.5px -1px rgba(5, 55, 95, 0.12);
  animation-duration: 1s;
  animation-fill-mode: forwards;
  backface-visibility: hidden;

  ${(props) =>
    props.hasNoReviews &&
    `
      padding: 16px 16px 32px 16px;
      
      ${onlyWidth(
        'xs',
        `
        &.scrolling-animation-enabled.scrolled-down {
          padding: 0;
        }
      `
      )};
  `};

  ${minWidth(
    'sm',
    `
      margin-bottom: ${gutterVariables.GUTTER_DOUBLE};
    `
  )};

  ${minWidth(
    'md',
    `
      gap: 40px;
      padding: 16px 16px 0 16px;
    `
  )};

  ${minWidth(
    'xxl',
    `
      justify-content: left; 
      text-align: left;
      padding: 24px 160px 40px 160px;
      border-radius: 0 0 8px 8px;
    `
  )};

  &.scrolling-animation-enabled {
    z-index: ${(props) =>
      props.isModalOpen ? 0 : zIndicesVariables.navigation};
    gap: 32px;
    animation-name: ${slideDownAnimationMobile};

    @media screen and (min-width: 768px) {
      animation-name: ${slideDownAnimation};
    }

    @media screen and (min-width: 1440px) {
      padding: 32px 160px 32px 160px;
    }
  }

  &.scrolled-up.scrolling-animation-enabled {
    position: fixed;
    transform: translateY(-80px);
    z-index: ${(props) =>
      props.isModalOpen ? 0 : zIndicesVariables.navigation};
    animation-name: ${slideUpAnimationMobile};
    animation-delay: 0.2s;

    @media screen and (min-width: 768px) {
      transform: translateY(-100px);
      animation-name: ${slideUpAnimation};
    }
  }

  &.scrolled-down.scrolling-animation-enabled {
    ${(props) =>
      props.hasExecutedInitialAnimation &&
      css`
        position: fixed;
        animation-name: ${slideBackUpAnimationMobile};
        animation-delay: 0.2s;

        @media screen and (min-width: 768px) {
          animation-name: ${slideBackUpAnimation};
        }
      `};
  }

  ${(props) =>
    props.isModalOpen &&
    css`
      nav {
        z-index: 0;
      }
    `}
`;

export const SummaryInfo = styled(Heading)<{
  isMemberProfile?: boolean;
  hasNoReviews?: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  text-align: left;
  margin-top: 16px;
  margin-bottom: 20px;
  align-items: center;

  ${minWidth(
    'sm',
    `
      display: inline;
      align-items: start;
      margin-bottom: 40px;
      gap: 20px;
      margin-top: 0;
    `
  )};

  ${minWidth(
    'md',
    `
      flex-direction: row;
      gap: 12px;
      flex-wrap: wrap;
      margin-bottom: 56px;
    `
  )};

  ${(props) =>
    props.isMemberProfile &&
    `
      ${minWidth(
        'md',
        `
              margin-bottom: 40px;
            `
      )};
  `};

  ${minWidth('xxl', `margin-bottom: 0;`)};

  && {
    font-weight: ${fontVariables.FONT_WEIGHT_REGULAR};
    color: ${colorsVariables.GRAY_600};
    font-size: ${fontVariables.SIZE_TEXT};
    line-height: ${fontVariables.LINE_HEIGHT_L};

    ${minWidth(
      'xxl',
      `
          font-size: ${fontVariables.SIZE_TEXT_M};
        `
    )};
  }

  &.scrolling-animation-enabled {
    display: none;
  }

  ${(props) =>
    props.hasNoReviews &&
    props.isMemberProfile &&
    `
    
      ${minWidth(
        'md',
        `
              margin-bottom: 32px;
            `
      )};
      
      ${minWidth(
        'xxl',
        `
              margin-bottom: 0;
            `
      )};
  `};
`;

export const CompanyName = styled(Heading)<{ hasNoReviews: boolean }>`
  margin-bottom: 20px;

  & {
    line-height: 0;
    display: inline;
  }

  ${(props) =>
    props.hasNoReviews &&
    css`
      ${flexColumn};
      line-height: 1.5;

      ${minWidth(
        'md',
        `
        flex-direction: row; 
        align-items: center;
      `
      )};
    `};

  &.scrolling-animation-enabled {
    margin-bottom: 0;
  }
`;

export const ShortenedCompanyName = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 23px;
  line-height: normal;
  justify-content: center;
  margin-bottom: 22px;

  ${minWidth('sm', `justify-content: start;`)};

  ${minWidth(
    'md',
    `font-size: 26px; margin-bottom: 20px; justify-content: start;`
  )};

  & > div {
    height: 28px;
  }

  && > span {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;

    :before {
      line-height: ${fontVariables.LINE_HEIGHT_XXL};
      ${minWidth('xxl', 'line-height: 42px;')};
    }
  }

  &.scrolling-animation-enabled {
    font-size: 23px;
    line-height: ${fontVariables.LINE_HEIGHT_XXL};
    margin-bottom: ${gutterVariables.GUTTER_HALF};
  }
`;

export const StarsRatingWrapper = styled.div<{ hasRatings?: boolean }>`
  display: flex;
  background-color: ${colorsVariables.WHITE};
  border-radius: 32px;
  width: fit-content;
  align-items: center;
  margin-bottom: 20px;
  min-width: 100%;
  justify-content: center;
  gap: 8px;

  ${(props) =>
    props.hasRatings &&
    `
      cursor: pointer;
  `};

  ${minWidth('sm', `justify-content: start;`)};
  ${minWidth('md', `gap: 12px; margin-bottom: 32px;`)};
  ${minWidth('xxl', 'margin-bottom: 0;')};

  &.scrolling-animation-enabled {
    margin-bottom: 0;
  }
`;

export const AverageRating = styled.span`
  font-size: 18px;
  font-weight: ${fontVariables.FONT_WEIGHT_MEDIUM};
  color: ${colorsVariables.GRAY_700};
  line-height: 8px;
  white-space: nowrap;

  &.scrolling-animation-enabled {
    font-size: 16px;

    ${minWidth(
      'xxl',
      `
      line-height: 16px;
    `
    )};
  }
`;

export const CompanyLogoLinkWrapper = styled.a`
  ${flexItemsCentered};
  margin-bottom: 20px;
  background: white;
  width: 144px;
  min-width: 144px;
  height: 72px;
  justify-content: center;

  ${minWidth(
    'sm',
    `
      margin-right: 20px;
      margin-bottom: 0;
    `
  )};

  ${minWidth(
    'xxl',
    `
      margin-right: ${gutterVariables.GUTTER_DOUBLE};
    `
  )};

  &.scrolling-animation-enabled {
    width: 124px;
    min-width: 124px;
    height: 64px;
    margin-top: 0;

    ${minWidth(
      'xxl',
      `
        margin-right: ${gutterVariables.GUTTER_PLUS_HALF};
    `
    )};
  }
`;

export const HeaderLogoAndContent = styled.div<{
  isMemberProfile?: boolean;
  hasNoReviews?: boolean;
}>`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;

  ${onlyWidth(
    'xs',
    `
      &.scrolling-animation-enabled {
        display: none;
      }
  `
  )};

  ${minWidth(
    'sm',
    `
      flex-direction: row;
      align-items: start;
    
      &.scrolling-animation-enabled {
        flex-direction: row;
        align-items: center;
      }
    `
  )};

  ${minWidth(
    'md',
    `
      display: flex;
      width: 634px;
    `
  )};

  ${minWidth(
    'lg',
    `
      width: 754px;
    `
  )};

  ${minWidth(
    'xxl',
    `
      width: 1120px;
    `
  )};

  ${(props) =>
    !props.isMemberProfile &&
    `
    & {
      flex-direction: column;
    }
      
      ${minWidth(
        'xxl',
        `
      flex-direction: row;
    `
      )};
  `};

  ${(props) =>
    props.hasNoReviews &&
    `
    &.scrolling-animation-enabled {
        margin-bottom: 32px;
        
        ${onlyWidth(
          'sm',
          `
            margin-bottom: 0;
    `
        )};
        
        ${minWidth(
          'xxl',
          `
            margin-bottom: 0;
    `
        )};
    }
  `};
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${onlyWidth('xs', 'width: 100%')};
  ${minWidth(
    'sm',
    `
      text-align: left;
    `
  )};
`;

export const HeaderContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
  align-items: center;

  ${minWidth(
    'sm',
    `
      align-items: start;
    `
  )};

  ${minWidth(
    'xxl',
    `
      flex-direction: row;
    `
  )};

  &.scrolling-animation-enabled {
    flex-direction: row;
  }
`;

export const RatingInPeriod = styled.span`
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  justify-content: center;

  ${minWidth('sm', 'text-align: left; display: inline;')};

  &:not(:first-child) {
    &:before {
      content: '';

      ${minWidth(
        'sm',
        `
          content: '|';
          margin: 0 8px;
        `
      )};

      ${minWidth(
        'xxl',
        `
       
            margin: 0 12px;
          `
      )};
    }
  }
`;

export const BoldedText = styled.span`
  font-weight: ${fontVariables.FONT_WEIGHT_MEDIUM};
`;

export const ReviewsSubheading = styled.span<{
  scrollingAnimationEnabled?: boolean;
}>`
  ${(props) =>
    props.scrollingAnimationEnabled &&
    `
      display: none;
  `};

  font-weight: ${fontVariables.FONT_WEIGHT_MEDIUM};
  font-size: ${fontVariables.SIZE_TEXT};
  color: ${colorsVariables.GRAY_700};
`;
